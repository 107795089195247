import { FC, useState } from "react";
import { Modal } from "../Modal/Modal";
import "./NewsPageItem.scss";
import { NewsPageData } from "../Pages/NewsPage/newsPageData";

export const NewsPageItem: FC<NewsPageData> = ({ title, date, img, text }) => {
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <div className="news__name">
        <h3 className="news__title-min title-min">{title}</h3>
        <span className="news__date text">{`Дата публикации: ${date}г.`}</span>
      </div>
      <div className="news__dscr">
        <img
          onClick={() => setModalActive(true)}
          className="news__img"
          src={img}
          alt={title}
        />
        <p className="news__text text">{text}</p>
      </div>
      <Modal active={modalActive} setActive={setModalActive}>
        <>
          <h3 style={{textAlign:'center'}} className="title-min">{title}</h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ maxWidth: "300px" }}
              className="modal__img-news"
              src={img}
              alt={title}
            />
          </div>
        </>
      </Modal>
    </>
  );
};
