import './ModalBurger.scss'
import svg from "../../assets/img/sprite.svg";

import { FC, ReactElement } from 'react';

interface ModalBurgerProps {
    open: boolean,
    setOpen:(value:boolean)=>void,
    children:ReactElement,
  }

export const ModalBurger:FC<ModalBurgerProps> = ({open, setOpen, children}) => {

    return (
        <div  className="modal-burger">
        <button onClick={() => setOpen(!open)} id="modal-burger__btn">
          <svg
            className="modal-burger__close"
            width="30px"
            height="30px"
          >
            <use xlinkHref={`${svg}#x`}></use>
          </svg>
        </button>
        <div className="modal-burger__conteiner">
         {children}
        </div>
      </div>
    )
}