import kraska from "../../assets/img/catalog/avokraska.jpg";
import diski from "../../assets/img/catalog/diski.jpg";
import abraziv from "../../assets/img/catalog/abraziv.webp";
import acrylic from "../../assets/img/catalog/ak.jpg";
import alkid from "../../assets/img/catalog/al.jpg";
import mix from "../../assets/img/catalog/mix.jpg";
import sintal from "../../assets/img/catalog/sintal.jpg";
import razliv from "../../assets/img/catalog/razliv.jpg";
import spray from "../../assets/img/catalog/spray-text.jpg";
import techline from "../../assets/img/catalog/TL.webp";
import shpatlevka from "../../assets/img/catalog/shpatlevka.webp";
import water from "../../assets/img/catalog/water.png";
import soft from "../../assets/img/catalog/soft.jpg";
import plastic from "../../assets/img/catalog/plastic.jpg";
import alu from "../../assets/img/catalog/alu.jpg";
import glass from "../../assets/img/catalog/glass.jpg";
import uni from "../../assets/img/catalog/uni.jpg";
import finish from "../../assets/img/catalog/finish.jpg";
import lak from "../../assets/img/catalog/lak.png";
import hs from "../../assets/img/catalog/hs.png";
import ms from "../../assets/img/catalog/ms.png";
import grunt from "../../assets/img/catalog/grunt.jpg";
import oneK from "../../assets/img/catalog/1k.jpg";
import twoK from "../../assets/img/catalog/2k.jpg";
import pl from "../../assets/img/catalog/pl.jpg";
import auto from "../../assets/img/catalog/auto.png";
import anti from "../../assets/img/catalog/anti.jpg";
import wd40 from "../../assets/img/catalog/wd40k.jpg";
import snow from "../../assets/img/catalog/snow.jpg";
import razbavitel from "../../assets/img/catalog/razbavitel.jpg";
import brake from "../../assets/img/catalog/brake.jpg";
import freezz from "../../assets/img/catalog/freezz.jpg";
import krug from "../../assets/img/catalog/krug.jpg";
import bumaga from "../../assets/img/catalog/bumaga.jpg";
import poloski from "../../assets/img/catalog/poloski.jpg";
import matirush from "../../assets/img/catalog/matirush.jpg";
import prochie from "../../assets/img/catalog/prochie.jpg";
import zashita from "../../assets/img/catalog/zashita.jpg";
import germetik from "../../assets/img/catalog/germetik.jpg";
import lenta from "../../assets/img/catalog/lenta.jpg";
import polirol from "../../assets/img/catalog/polirol.jpg";
import remcomplect from "../../assets/img/catalog/remcomplect.jpg";
import salfetki from "../../assets/img/catalog/salfetki.jpg";
import projvka from "../../assets/img/catalog/projvka.png";

export interface CatalogData {
  id: number;
  title: string;
  dscr: string;
  img: string;
  catalogItem: CatalogDataItem[];
  searchWords: string[];
}

export interface CatalogDataItem {
  idItem: number;
  titleItem: string;
  dscrItem: string;
  imgItem: string;
}

export type CatalogArr = CatalogData[];

export const catalogData: CatalogArr = [
  {
    id: 1,
    title: "Автоэмали",
    dscr: "Автомобильную краску от лучших российских и зарубежных производителей (vika, mobihel, body), Вы можете купить, заказать в Нижнем Новгороде. Магазин «Красыч» в своём каталоге имеет широкий выбор видов авто эмалей.",
    img: kraska,
    searchWords: [
      "Автоэмали",
      "Автокраска",
      "Mobihel",
      "Vika",
      "Акриловая эмаль",
      "Алкидная эмаль",
      "Металлик",
      "Синтал",
      "Автоэмали на разлив",
      "Аэрозоль",
      "Спрей",
    ],
    catalogItem: [
      {
        idItem: 1.1,
        titleItem: "Акриловая эмаль",
        dscrItem:
          "При использовании акриловой автоэмали Вы получите отличное качество и однородность окрашенной поверхности в сочетании с более высоким блеском, т.к. в основе акриловой краски лежат синтетический смолы, получаемые из нефти. Краска достаточно быстро сохнет и после этого становится очень устойчивой к механическим воздействиям и агрессивным растворам. При покупке так же следует учитывать и недостаток акриловой краски, это низкую покрывающую способность, из-за чего покраску необходимо осуществлять в несколько слоев. После нанесения акриловой краски не рекомендуется производить дополнительное покрытие поверх лаком.",
        imgItem: acrylic,
      },
      {
        idItem: 1.2,
        titleItem: "Алкидная эмаль",
        dscrItem:
          "Хорошим соотношением цены-качества является алкидная эмаль для покраски авто. К очевидным преимуществом этого вида красок являются низкая стоимость при относительно высоких показателях стойкости к механическим и химическим воздействиям, а также хорошее качество покрытия. Краска легко наносится и образует довольно толстые слои, что снижает количество стадий покраски.",
        imgItem: alkid,
      },
      {
        idItem: 1.3,
        titleItem: "Металлик",
        dscrItem:
          "Покраска автомобиля металликом намного сложнее, чем покраска обычной эмалью, и имеет большое количество подводных камней, которые могут серьезно испортить работу, вызвав потерю времени и средств, но и результат будет лучше обычного покрытия. Перед тем, как приступить к покрасочным работам, необходимо внимательно изучить технологию покраски автомобиля металликом и сделать пробную покраску какого либо элемента.",
        imgItem: mix,
      },
      {
        idItem: 1.4,
        titleItem: "Синтал",
        dscrItem:
          "Краска автомобильная МЛ-1110 (синтал) представляет собой суспензию пигментов в растворе алкидных и меламино-формальдегидных смол в органических растворителях с добавлением специальных добавок. Используется для покраски любого автомобиля или металлических деталей автомобилей после предварительной подготовки поверхности. Автоэмаль долго держится, долго не теряет цвет, защищая при этом также кузов автомобиля. Данная автомобильная краска отлично зарекомендовала себя на конвейерах ведущих российских автозаводах.",
        imgItem: sintal,
      },
      {
        idItem: 1.5,
        titleItem: "Автоэмали на разлив",
        dscrItem:
          "В наших магазинах по мимо готовых автомобильных красок в банках или аэрозоле, Вы можете купить в Нижнем Новгороде автоэмали на разлив. Много цветов в наличии, а так же быстрая доставка на заказ. Разлив автоэмалей осуществляется от 100гр.",
        imgItem: razliv,
      },
      {
        idItem: 1.6,
        titleItem: "Автоэмали в аэрозоле",
        dscrItem:
          "Автоэмали в аэрозоле – это одна из самых удобных форм выпуска краски. Автокраски в аэрозоле пользуются большой популярностью на рынке. Они удобные в эксплуатации, быстро сохнут и стойко окрашивают поверхность автомобиля. Автоэмаль спрей не требует специальных навыков и широко используется как автолюбителями для маскировки мелких дефектов, так и профессионалами в специализированных мастерских.",
        imgItem: spray,
      },
    ],
  },
  {
    id: 2,
    title: "Литые диски",
    dscr: "Литые диски для авто являются не только украшением вашего автомобиля, но и являются важнейшей составляющей ходовой части. У нас Вы найдёте только проверенные бренды литых дисков, которые отвечают всем требованием безопасной езды.",
    img: diski,
    searchWords: ["Литые диски", "Колёсные диски", "Колесные диски", "Tech Line"],
    catalogItem: [
      {
        idItem: 2.1,
        titleItem: "Литые диски Tech Line",
        dscrItem:
          "Литые диски фирмы Tech Line изготавливаются по технологии низкого давления. Процесс изготовления дисков литьем при низком давлении способствует достижению легкости и высокой механической прочности дисков Tech Line. Результат - снижается вес диска Теч Лайн и следовательно, уменьшается потребляемое топливо, продлевается срок эксплуатации шин, значительно снижается уровень толчков от шин при вождении, вследствие уменьшения неподрессорной массы автомобиля, и наконец, экономия топлива ведет к снижению уровня загрязнения воздуха.",
        imgItem: techline,
      },
    ],
  },
  {
    id: 3,
    title: "Шпатлевки",
    dscr: "Шпаклевание является очень важным этапом в ремонте авто. Это связано с тем, что от правильного выбора шпаклевки, а также ее нанесения на металлическую поверхность, будет зависеть качество нанесения окрасочного слоя, а также форма кузова. Металлу нужна качественная смесь. В наше время производителе предоставляют огрормный выбор шпатлёвок. Самые популярные виды, вы найдёте в нашем магазине Красыч, а определиться с выбором шпатлёвки, Вам помогут наши специалисты.",
    img: shpatlevka,
    searchWords: [
      "Шпатлевки",
      "Шпатлевки жидкие",
      "Шпатлевки мягкие",
      "Шпатлевки по пластику",
      "Шпатлевки с алюминием",
      "Шпатлевки со стекловолокном",
      "Шпатлевки универсальные",
      "Финишная шпатлевка",
      "Novol",
      "Reoflex",
    ],
    catalogItem: [
      {
        idItem: 3.1,
        titleItem: "Шпатлевки жидкие",
        dscrItem:
          "Жидкая шпатлевка для авто, наносится с помощью специального пистолета, или же обычного пульверизатора. Позволяет получить идеально гладкую поверхность даже на больших площадях. Время её затвердевания составляет около 2 часов, однако время можно сократить, увеличив температуру не выше 60°C. Шпатлёвка применяется как для механической, так и ручной обработки мелкозернистой наждачной бумагой.",
        imgItem: water,
      },
      {
        idItem: 3.2,
        titleItem: "Шпатлевки мягкие",
        dscrItem:
          "Шпатлевки мягкие SOFT - это высокореактивные полиэфирные шпатлевки с меньшей чувствительностью к более низким температурам и увеличенным содержанием влаги. Быстро сохнет, что обеспечивает качественную и быструю работу как при более низких температурах (100С) так при повышенной влаге (больше 60 %). Кремовая консистенция шпатлевки обеспечивает хорошую смазочную способность, наносимый слой не имеет видимых пор. Шпатлёвка мягкая, может поддаваться как ручной, так и механической обработке.",
        imgItem: soft,
      },
      {
        idItem: 3.3,
        titleItem: "Шпатлевки по пластику",
        dscrItem:
          "Для ремонта различных пластиковых деталей кузова авто используется шпатлевка для пластика. Шпатлевка для пластмассовых комплектующих авто производится в двухкомпонентных составах. Их формирование происходит путем смешивания основы из полиэфирных смол с активатором в пропорции примерно 5:100. Далее шпателем шпатлевка наносится на участок авто и выравнивается. После сушки, которую можно произвести и в условиях гаража без использования УФ-излучения, поверхность авто шлифуется крупнозернистой, а затем — мелкозернистой наждачкой.",
        imgItem: plastic,
      },
      {
        idItem: 3.4,
        titleItem: "Шпатлевки с алюминием",
        dscrItem:
          "Наполняющая шпатлёвка с добавкой алюминиевой пыли применяется в местах, где присутствует вибрация или возможны перепады температур. Даже толстый слой усиленной алюминием шпатлевки не трескается и не отлетает от обрабатываемой поверхности при вибрационном или температурном воздействии. Шпатлевки с алюминием обладают меньшей усадкой по сравнению с типичными шпатлевками, а также высокой эластичностью, благодаря этому применяется для выравнивания больших по площади неровностей.",
        imgItem: alu,
      },
      {
        idItem: 3.5,
        titleItem: "Шпатлевки со стекловолокном",
        dscrItem:
          "Шпатлевки со стекловолокном обладают отличными показателями твердости. Вследствие нанесения стекловолоконной смеси на поверхность кузова автомобиля, раствор по факту становится твердым стеклопластиком. Так же он отлично шлифуется. Такой вид материала со стекловолокном в своем составе наиболее выгодно подходит для маскирования сквозных дыр в корпусе авто, возникшим по причине коррозии. Также легко справится с дырами в бампере. Стекловолокно идеально в ремонте кузова.",
        imgItem: glass,
      },
      {
        idItem: 3.6,
        titleItem: "Шпатлевки универсальные",
        dscrItem:
          "Универсальная смесь в силах стать достойной альтернативой крупнозернистым, а также мелкозернистым шпаклевкам. Универсальный вид является очень прочным материалом, и поэтому его вполне можно использовать в роли наполнителя для пластика.",
        imgItem: uni,
      },
      {
        idItem: 3.6,
        titleItem: "Финишная шпатлевка",
        dscrItem:
          "Автомобильная финишная (мелкозернистая) шпатлевка - Используются для устранения небольших дефектов поверхности автомобиля. Благодаря мелким наполнителям, идеально маскируют царапины и иные мелкие дефекты. Последняя стадия шпатлевания машины производится именно этим видом материала.",
        imgItem: finish,
      },
    ],
  },
  {
    id: 4,
    title: "Автомобильный лак",
    dscr: "Для покраски автомобиля используют твердые и мягкие лаки. Во всем мире принято обозначение HS и MS соответственно. Твердый лак для авто, также как и мягкий, имеет свои особенности, преимущества и недостатки. Магазин «Красыч» предлагает широкий ассортимент автомобильных лаков, в котором каждый автовладелец сможет подобрать средство, полностью отвечающее запросам: цена, качество, удобство использования. Разумная ценовая политика и консультационная поддержка позволят делать полезные покупки с удовольствием.",
    img: lak,
    searchWords: ["Автомобильный лак", "Лак HS", "Лак MS", "Лак в аэрозоле", "Vika", "Mobihel", "Reoflex"],
    catalogItem: [
      {
        idItem: 4.1,
        titleItem: "Лак HS",
        dscrItem:
          "Автомобильные лаки HS (High Solid) — высокое содержание сухого вещества. Лак HS наносится в полтора слоя по следующей схеме: первый слой – тонкий, практически невесомый, второй – основной, более толстый (40-50 микрон). Авто лак HS довольно густой и обладает небольшой текучестью, поэтому на корпусе авто почти не образуется подтеков. У HS лаков более лучший блеск, но сохнет он достаточно долго, даже при использовании быстрого отвердителя. Для HS-лака зачастую применяют ИК-сушку, да бы сэкономить время.",
        imgItem: hs,
      },
      {
        idItem: 4.2,
        titleItem: "Лак MS",
        dscrItem:
          "Автомобильные лаки MS (Medium Solid) — это среднее содержание сухого вещества. Лак MS более жидкий и текучий. Для качественной покраски автомобиля понадобится 2-3 слоя, и необходимо внимательно следить за их толщиной, чтобы избежать появления подтеков. Между нанесением слоев нужно выдерживать не менее 10-15 минут. При лакировке MS лаком на кузове авто остается только 40-60%. Поэтому полировать авто лак MS гораздо легче за счет его мягкости, да и сохнет он быстрее.",
        imgItem: ms,
      },
      {
        idItem: 4.3,
        titleItem: "Лак в аэрозоле",
        dscrItem:
          "Лаки в баллончиках – это особая разновидность лаковых смесей, которая отличается простотой и удобством использования. На данный момент времени многие известны марки автомобильных лаков выпускают свою продукцию в аэрозольных баллонах. Лак аэрозоль не требует опыта работы в лакировке авто, на обрабатываемую поверхность авто ложится чётко и без подтёков, имеет прекрасную вязкость.",
        imgItem: spray,
      },
    ],
  },
  {
    id: 5,
    title: "Грунт для авто",
    dscr: "Грунт для авто – это база для лакокрасочных покрытий. Он защищает металл от процесса коррозии. Если шпатлевку используют для коррекции формы деталей автомобиля, то грунтовка помогает бороться с образованием ржавчины. Поэтому при окраске автомобиля очень важно правильно подобрать не только краску, но и грунтовку. Автомобильная грунтовка является промежуточным слоем между основным металлом кузова и финишным лакокрасочным покрытием, т.е. краской. Поэтому на автомобильной грунтовке не стоит экономить, ведь грунтовка – фундамент для краски и дополнительная защита от коррозии. Хорошо подготовленная перед окраской поверхность – залог долговечности готового лакокрасочного покрытия.",
    img: grunt,
    searchWords: [
      "Грунт для авто",
      "Однокомпонентный акриловый грунт",
      "Двухкомпонентный акриловый грунт",
      "Vika",
      "Mobihel",
      "Novol",
      "Reoflex"
    ],
    catalogItem: [
      {
        idItem: 5.1,
        titleItem: "Однокомпонентный акриловый грунт",
        dscrItem:
          "Однокомпонентный акриловый грунт можно наносить тонким слоем. Он отлично подходит для новых деталей и обладает хорошей адгезией. Обычно накладывают три тонких слоя, выдержка между слоями 5-8 минут. Однокомпонентная грунтовка не содержит отвердителей (от которых зависит, сколько времени сохнет грунт), поэтому долго высыхает и используется редко.",
        imgItem: oneK,
      },
      {
        idItem: 5.2,
        titleItem: "Двухкомпонентный акриловый грунт",
        dscrItem:
          "Двухкомпонентный акриловый грунт популярный вид грунтовки авто, т.к. данный грунт можно считать универсальным и использовать его при любом виде кузовного ремонта. Такой автомобильный грунт отличается превосходной адгезией и благодаря входящим в состав отвердителям высыхает за сутки. Чаще всего грунтовка двухкомпонентная используется на последнем этапе выравнивания поверхности. С ее помощью можно заполнить небольшие поры и зашлифовать риски.",
        imgItem: twoK,
      },
      {
        idItem: 5.3,
        titleItem: "Грунты в аэрозоле",
        dscrItem:
          "Если же для ремонта требуется небольшое количество грунтовки, то целесообразнее использовать аэрозольный грунт. Он отлично подходит для отдельных участков кузова, которые потеряли привлекательный внешний вид и протерлись до голого металла. Главное достоинство таких грунтовок заключается в их экономичном расходовании, и быстром времени высахания (10-15 минут). Как и акриловые грунты, грунт спрей обеспечивают хорошее сцепление лакокрасочного покрытия и основы и защищают поверхность от коррозии.",
        imgItem: spray,
      },
      {
        idItem: 5.4,
        titleItem: "Грунты для пластика",
        dscrItem:
          "Для ремонта по пластмассе (бампера, зеркала, спойлеры и пластмассовые решетки авто панелей) используются свои материалы и в том числе грунты. Грунты по пластику это всегда 1К – однокомпонентные составы. Их основная задача сцепиться с поверхностью пластика и стать адгезионной подложкой для последующих покрытий. Они не требуют разбавления, находятся в готовом виде, в основном прозрачного чуть желтоватого оттенка.",
        imgItem: pl,
      },
    ],
  },
  {
    id: 6,
    title: "Защита кузова автомобиля",
    dscr: "Защита кузова автомобиля от внешних воздействий (дождя, пыли, мелких царапин, возникающих при эксплуатации), является не обходимой профилактикой, которую необходимо производить раз в 3-4 года. Перед обработкой кузова защитным средством, важно знать, что если под предыдущим слоем есть ржавчина, то новый слой мастики никак на нее не подействует и она продолжит распространяться. Поэтому все обрабатываемые места необходимо тщательно очистить от грязи, ржавчины и всякого рода жиров. Только на чистой и обезжиренной поверхности сцепление антикоррозионных материалов с поверхностью будет надежным.",
    img: auto,
    searchWords: ["Защита кузова автомобиля", "Антигравий", "Мастика"],
    catalogItem: [
      {
        idItem: 6.1,
        titleItem: "Антигравий",
        dscrItem:
          "Антигравий — это лучший вариант для защиты кузова автомобиля. Средства, при помощи которых производится антигравийная обработка, не портят внешний вид автомобиля. Они имеют особенный состав, что позволяет им принимать на себя удар камешков и прочих твердых частиц, полностью его поглощая. Это, в свою очередь, сводит к минимуму вероятность повреждения кузова.",
        imgItem: anti,
      },
      {
        idItem: 6.2,
        titleItem: "Мастика",
        dscrItem:
          "Нижняя часть кузова непрерывно находится в условиях постоянной опасности разрушения, активного обстреливания песком, ледовой крошкой, щебнем и камешками, вылетающими из-под колес. Функции битумной мастики – консервация металла и защита кузова от повреждений. Средство одновременно защищает порог или днище от влияния агрессивной среды и предохраняет покрытие от разрушения. Но оно не предназначено для борьбы с уже появившейся коррозией.",
        imgItem: ms,
      },
    ],
  },
  {
    id: 7,
    title: "Жидкости",
    dscr: "Магазин Красыч, предлагает широкий выбор жидкостей для автомобиля. ",
    img: water,
    searchWords: [
      "Жидкости",
      "WD-40",
      "Незамерзайка",
      "Растворители",
      "Тормозная жидкость",
      "Тосол, антифриз",
    ],
    catalogItem: [
      {
        idItem: 7.1,
        titleItem: "WD-40",
        dscrItem:
          "WD-40 вытесняет влагу и образует защитный барьер против сырости. Защищает от коррозии даже в самых экстремальных условиях. Смазывает детали не оставляя жирных или липких следов. Проникает в механизмы, освобождая заржавевшие части. Легко удаляет жир, битумные пятна или остатки клея.",
        imgItem: wd40,
      },
      {
        idItem: 7.2,
        titleItem: "Незамерзайка",
        dscrItem:
          "С приходом зимы всем автолюбителям хочется, чтобы тепло и уютно было не только дома, поэтому многие задумываются об аксессуарах для своего автомобиля. Магазин красыч предлагает купить в Нижнем Новгороде незамерзающую жидкость для Вашего автомобиля.",
        imgItem: snow,
      },
      {
        idItem: 7.3,
        titleItem: "Растворители",
        dscrItem:
          "Растворители - это летучая жидкость, назначение которой, растворять твердое пленкообразующее и другие составные части лаков и красок. После нанесения ЛКМ на покрываемую поверхность растворитель из их состава испаряется, а пленкообразующее снова возвращается в твердое состояние в виде тонкой поверхностной пленки.",
        imgItem: razbavitel,
      },
      {
        idItem: 7.4,
        titleItem: "Тормозная жидкость",
        dscrItem:
          "Тормозная жидкость одна из основных жидкостей вашего автомобиля. Её главная функция передавать усилие от главного тормозного цилиндра к колесным.Интернет магазин Красыч расположенный в Нижнем Новгороде предлагает Вам пожалуй самую распространённую, качественную и за приемлемые деньги тормозную жидкостб фирмы ROSDOT, производитель Компания «Тосол-Синтез» расположенная в городе Дзержинск.",
        imgItem: brake,
      },
      {
        idItem: 7.5,
        titleItem: "Тосол, антифриз",
        dscrItem:
          "Различия между тосолом и антифризом. Антифриз - общее название охлаждающих жидкостей для автомобильных двигателей, тосол - торговая марка отечественного антифриза. Тосол содержит в своем составе силикат, осаждающийся на стенках каналов и защищающий их от коррозии. Требует замену раз в год. Антифриз менее агрессивен и потому силиката не содержит. Смешивать их нельзя как и нельзя смешивать цветные антифризы.",
        imgItem: freezz,
      },
    ],
  },
  {
    id: 8,
    title: "Абразивы",
    dscr: "У нас Вы можете приобрести качественные абразивные материалы. В ассортименте наждачная бумага, круги, полоски, матирующие губки и другие шлифовальные материалы, с помощью которых можно легко зачистить старый слой краски и привести в порядок любую поверхность.",
    img: abraziv,
    searchWords: [
      "Абразивы",
      "Абразивная бумага",
      "Абразивные полоски",
      "Абразивные круги",
      "Матирующие материалы",
    ],
    catalogItem: [
      {
        idItem: 8.1,
        titleItem: "Абразивная бумага",
        dscrItem:
          "Шлифовальная бумага рекомендуется для ручной мокрой шлифовки пластиков, эмалей, лаков, грунтов, композитных материалов. Изготовлена на основе карбида кремния с чрезвычайно высокой абразивной способностью.",
        imgItem: bumaga,
      },
      {
        idItem: 8.2,
        titleItem: "Абразивные полоски",
        dscrItem:
          "Абразивные материалы в полосках без отверстий специально разработаны для продолжительного эффективного шлифования металлов, лакокрасочных покрытий, шпатлёвок, пластмассы, дерева и т.д. с использованием шлифовального оборудования без системы пылеотвода. Применяются в таких областях, как ремонтная окраска транспортных средств, дерево- и металлообработка, производство мебели, бытовых приборов и т.д.",
        imgItem: poloski,
      },
      {
        idItem: 8.3,
        titleItem: "Абразивные круги",
        dscrItem:
          "Абразивные материалы на бумажной основе в кругах с отверстиями для пылеотвода и с использованием минерала оксида алюминия с высокой абразивной способностью и повышенной износостойкостью. Полировочные диски  с отверстиями специально разработаны для продолжительного эффективного шлифования металлов, лакокрасочных покрытий, шпатлёвок, пластмассы, дерева и т.д.",
        imgItem: krug,
      },
      {
        idItem: 8.4,
        titleItem: "Матирующие материалы",
        dscrItem:
          "Матирующие материалы  предназначены для обработки поверхности сложного профиля, придания формы и зашлифовки кромок. Идеально подходят для обработки труднодоступных участков. Абразивные губки Fine обеспечивают тонкую и супер тонкую шлифовку профилей, выполненных как из массива, так и из древесных материалов (ДСП, MДФ).",
        imgItem: matirush,
      },
    ],
  },
  {
    id: 9,
    title: "Прочее",
    dscr: "В разделе «Прочее» собран обширный ассортимент товара, не вошедшего в другие категории. Здесь Вы сможете найти рем комплекты, средства защиты, проявочные покрытия, герметики много другое. Товары, собранные в данном разделе, предназначены для проведения разнообразных авторемонтных работ.",
    img: prochie,
    searchWords: ["Автомобильные герметики", "Автомаляр", "Ленты и скотч", "Материалы для полировки", "Проявочные покрытия", "Ремонтные комплекты", "Автомобильные салфетки", "Novol", "Reoflex", "Vika"],
    catalogItem: [
      {
        idItem: 9.1,
        titleItem: "Автомобильные герметики",
        dscrItem:
          "Самыми популярными являются универсальные силиконовые автомобильные герметики: они обладают высокой прочностью и эластичностью, а для застывания им требуется не больше десяти минут. Термостойкие автомобильные герметики созданы для замены прокладочных материалов: этот вид отличает устойчивость к вибрации и перепадам температуры. Герметик для автомобильных стекол поможет вклеить лобовое стекло, а полиуретановый герметик для швов пригодится при обработке стыков металла.",
        imgItem: germetik,
      },
      {
        idItem: 9.2,
        titleItem: "Автомаляр",
        dscrItem:
          "В магазине Красыч, вы можете заказать и купить инструменты для необходимые Вам для покраски авто (Краскопульт, пистолет под герметик, шпателя), а так же защиту для себя и своего авто (очки и полумаски, маски и респираторы, комбинезоны, перчатки, пленка укрывочная и много другое). Широкий ассортимент и демократичные цены позволят Вам приобрести именно то, что нужно.",
        imgItem: zashita,
      },
      {
        idItem: 9.3,
        titleItem: "Ленты и скотч",
        dscrItem:
          "Малярная лента на бумажной основе используется для защиты поверхностей при покраске кузова автомобиля.  Например, для защиты уплотнителей стекол от перепыла. Нанесенный на основу клей обладает низкой адгезией, что позволяет легко снимать ленту с любых поверхностей не опасаясь оставить липкие следы.",
        imgItem: lenta,
      },
      {
        idItem: 9.4,
        titleItem: "Материалы для полировки",
        dscrItem:
          "У нас Вы найдете: полировальные круги для авто разной степени жесткости и полировальные пасты. В наличии синтетические, шерстяные, меховые, войлочные полировальные круги, служащие для проведения различных работ, начинаю от зачистки поверхности и заканчивая финишной подготовкой. Полировочные пасты придают покрытию зеркальный эффект и успешно маскируют небольшие царапины, тем самым продлевая срок службы кузова в целом.",
        imgItem: polirol,
      },
      {
        idItem: 9.5,
        titleItem: "Проявочные покрытия",
        dscrItem:
          "Сухое проявочное покрытие очень полезная штука при покраске автомобиля. Она помогает выявить: 1. На зашпатлеванной поверхности основные неровности, выбоины и наслоения шпатлевки. 2. На загрунтованной поверхности мелкие дефекты, кратера, сколы, царапины, мусор.",
        imgItem: projvka,
      },
      {
        idItem: 9.6,
        titleItem: "Ремонтные комплекты",
        dscrItem:
          "В случае поломки какой-либо системы автомобиля необходимо быстро устранить неполадку. Специалисты рекомендуют использовать для этого ремонтные комплекты, которые помогут сэкономить время на восстановление и обеспечат полную безопасность каждой функциональной части машины и ее водителя. Магазин Красыч предлагает для своих покупателей ремкомплекты, которые подойдут для большинства автомобилей, и позволят быстро наладить безупречную работу.",
        imgItem: remcomplect,
      },
      {
        idItem: 9.7,
        titleItem: "Автомобильные салфетки",
        dscrItem:
          "Салфетки являются универсальным и недорогим средством для очищения различных поверхностей от грязи и пыли. В данном разделе Вы сможете найти специальные автомобильные салфетки для обезжиривания, салфетки для удаления масел, липкие, полировальные и антистатические салфетки, а также большое количество других салфеток для разных поверхностей.",
        imgItem: salfetki,
      },
    ],
  },
];
