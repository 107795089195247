import { ChangeEvent, FC } from "react";
import "./Search.scss";
import svg from "../../assets/img/sprite.svg";
import { SetURLSearchParams } from "react-router-dom";

interface SearchProps {
  searchCatalog: string;
  /* searchParam: URLSearchParams */
  setSearchParam: SetURLSearchParams;
}

export const Search: FC<SearchProps> = ({ setSearchParam, searchCatalog }) => {
  
  const handlesSearchCatalog = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchParam({ searchCatalog: e.target.value });
  };

  return (
    <div className="search">
      <input
        value={searchCatalog}
        onInput={handlesSearchCatalog}
        className="search__field"
        placeholder="Поиск по каталогу"
      />
      <svg className="search__icon" width="20" height="20" fill="none">
        <use xlinkHref={`${svg}#search`}></use>
      </svg>
      <button onClick={() => setSearchParam("")} className="search__clear-btn">
        <svg className="search__clear-svg" width="30" height="30" fill="none">
          <use xlinkHref={`${svg}#x`}></use>
        </svg>
      </button>
    </div>
  );
};
