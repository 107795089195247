import { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { CatalogContext } from "../Catalog/CatalogContext";
import "./CatalogManufacturers.scss";

import vika from "../../assets/img/catalog/vika.jpg";
import mobihel from "../../assets/img/catalog/mobihel.jpg";
import logoreoflex from "../../assets/img/catalog/logoreoflex.jpg";
import novol from "../../assets/img/catalog/novol.jpg";

export const CatalogManufacturers = () => {
  /* Что бы не забыть как пользоваться useContext */
  const catalogDataContext = useContext(CatalogContext);
  const autopaint = catalogDataContext[0].title;

  return (
    <div className="catalog__manufacturers">
      <h3 className="catalog__title-min title-min">Производители</h3>
      <ul className="catalog__list catalog__list_manufacturers">
        <li className="catalog__item">
          <Link
            to={{
              hash: "#catalog",
              search: "?searchCatalog=Vika",
            }}
          >
            <img
              className="catalog__manufacturers-img"
              src={vika}
              alt={`vika${autopaint}`}
            />
          </Link>
        </li>
        <li className="catalog__item">
          <Link
            to={{
              hash: "#catalog",
              search: "?searchCatalog=mobihel",
            }}
          >
            <img
              className="catalog__manufacturers-img"
              src={mobihel}
              alt="mobihel"
            />
          </Link>
        </li>
        <li className="catalog__item">
          <Link
            to={{
              hash: "#catalog",
              search: "?searchCatalog=reoflex",
            }}
          >
            <img
              className="catalog__manufacturers-img"
              src={logoreoflex}
              alt="reoflex"
            />
          </Link>
        </li>
        <li className="catalog__item">
          <Link
            to={{
              hash: "#catalog",
              search: "?searchCatalog=novol",
            }}
          >
            <img
              className="catalog__manufacturers-img"
              src={novol}
              alt="novol"
            />
          </Link>
        </li>
      </ul>
    </div>
  );
};
