import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <section className="404">
    <div className="container">
      <h2 className="title">Ошибка 404</h2>
      <p className="text">Страница не найдена перейти на <Link style={{color: "blue"}} to={'/'}> главную</Link></p>
    </div>
    </section>

  );
}
