import "./Paint.scss";
import podbor from '../../assets/img/podbor.webp'

export const Paint = () => {
  return (
    <section id="paint" className="paint p70">
      <div className="container paint__container">
        <h2 className="paint__title title">Подбор краски для автомобиля</h2>
        <div className="paint__block">
          <img className="paint__img" src={podbor} alt="подбор краски" />
        <p className="paint__text text">
          Компьютерный подбор краски&nbsp;&mdash; это процесс смешивание
          основных компонентов, в&nbsp;определённых пропорциях, для получения
          необходимого цветового оттенка. Подбор автоэмали для автомобиля
          в&nbsp;Нижнем Новгороде осуществляется с&nbsp;помощью компьютера.
          Вы&nbsp;приносите нам крышку бензобака (личок), по&nbsp;адресу который
          находится в&nbsp;разделе контакты, мы&nbsp;в&nbsp;течение 3-х дней
          осуществляем подбор краски и&nbsp;привозим готовую автоэмаль в нужном
          вам объёме, в&nbsp;магазин. Для того, чтобы более точно была подобрана
          автоэмаль, надо понять, какой краской был выкрашен автомобиль
          на&nbsp;конвейере. Для этого нам и&nbsp;нужен color code или код
          краски. Код краски на&nbsp;автомобиле, как правило, находится на
          табличке-шильдике, которая устанавливается в&nbsp;определенном месте
          заводом изготовителем.
        </p>
        </div>
        
      </div>
    </section>
  );
};
