import './Reviews.scss'
import { reviewsData } from './reviewsData'

export const Reviews = () => {

    return (
        <section id="reviews" className="reviews p70">
        <div className="container reviews__container">
          <h2 className="reviews__title title">
            Отзывы
          </h2>
          <ul className="reviews__list">
          {reviewsData.map(({id,name, text, img}) => (
            <li key={id} className="reviews__item">
              <img className="reviews__img" src={img} alt={name}/>
            <h3 className="reviews__name title-min">
              {name}
            </h3>
            <p className="reviews__text text">
              {text}
            </p>
          </li>
          ))}

            </ul>
        </div>
      </section>
    )
} 