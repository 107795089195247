import review1 from '../../assets/img/rev1.webp'
import review2 from '../../assets/img/rev3.webp'
import review3 from '../../assets/img/rev2.webp'
import review4 from '../../assets/img/rev4.webp'


export interface ReviewsType {
    id: number
    name: string
    text: string
    img: string
}

export type ReviewsArr = ReviewsType[]

export const reviewsData:ReviewsArr = [
    {
        id: 1,
        name: 'Владимир',
        text: 'Спасибо магазину Красыч за подбор краски для моего автомобиля, всё было было сделано быстро и качествено ',
        img: review1
    },
    {
        id: 2,
        name: 'Дмитрий',
        text: 'Я владелец сервиса по ремонту автомобилей и уже долгое время сотрудничаю с магазином Красыч, ведь у них всегда в наличии большой выбор автоэмалей и сопутствующих товаров для покраски авто',
        img: review2
    },
    {
        id: 3,
        name: 'Пётр',
        text: 'Я мало что понимаю в покраске авто, но заглянув в магазин Красыч, мне помогли с выбором. Спасибо магазину Красыч, буду обращаться ещё...',
        img: review3
    },
    {
        id: 4,
        name: 'Иван',
        text: 'Постоянно хожу в магазин Красыч за автомобильным лаком, только у тут я получаю грамотную помощь при выборе товара и приятные цены',
        img: review4
    },
]