import { FC, useMemo } from "react";
import { CatalogList } from "../CatalogList/CatalogList";
import "./Catalog.scss";
import { CatalogArr } from "./catalogData";
import { Search } from "../../ui/Search/Search";
import { useSearchParams } from "react-router-dom";
import { CatalogManufacturers } from "../CatalogManufacturers/CatalogManufacturers";

interface catalogDataProps {
  catalogData: CatalogArr;
}

export const Catalog: FC<catalogDataProps> = ({ catalogData }) => {
  const [searchParam, setSearchParam] = useSearchParams();

  const searchCatalog = searchParam.get("searchCatalog") || "";

  const filteredResults = useMemo(() => {
    return catalogData.filter(({ searchWords }) =>
      searchWords.toString().toLowerCase().includes(searchCatalog.toLowerCase())
    );
  }, [searchCatalog, catalogData]);

  return (
    <section id="catalog" className="catalog p70">
      <div className="container catalog__container">
        <div className="catalog__block">
          <h2 className="catalog__title title">Каталог товаров</h2>
          <Search
            setSearchParam={setSearchParam}
            searchCatalog={searchCatalog}
          />
        </div>
        {filteredResults.length > 0 ? (
          filteredResults.map(
            ({ id, title, dscr, img, catalogItem, searchWords }) => {
              return (
                <div className="catalog__content" key={id}>
                  <CatalogList
                    id={id}
                    title={title}
                    dscr={dscr}
                    img={img}
                    catalogItem={catalogItem}
                    searchWords={searchWords}
                  />
                </div>
              );
            }
          )
        ) : (
          <div className="catalog__content">
            <h3 className="catalog__title-min title-min">
              По вашему запросу ни чего не найдено
            </h3>
          </div>
        )}
        <div className="catalog__content">
          <CatalogManufacturers />
        </div>
      </div>
    </section>
  );
};
