import slide1 from "../../assets/img/Magaz1.webp";
import slide2 from "../../assets/img/Magaz2.webp";
import slide3 from "../../assets/img/Magaz3.webp";
import slide4 from "../../assets/img/Magaz4.webp";
import slide5 from "../../assets/img/Magaz5.webp";

export interface Slides {
  id: number;
  slide: string;
}

type SlidesArr = Slides[];

export const slides: SlidesArr = [
  {
    id: 1,
    slide: slide1,
  },
  {
    id: 2,
    slide: slide2,
  },
  {
    id: 3,
    slide: slide3,
  },
  {
    id: 4,
    slide: slide4,
  },
  {
    id: 5,
    slide: slide5,
  },
];
