export interface ContactsType {
  id: number;
  title: string;
  address: string;
  map: string
  work: string;
  phone: string;
  phoneNum: number;
}
type ContactsArr = ContactsType[];

export const contactsData: ContactsArr = [
  {
    id: 1,
    title: "Кузбасский авторынок",
    address:
      "Адрес: г.Нижний Новгород, ул.Удмуртская 3к1, павильон «Автозапчасти», секция 22 и 35",
    work: "Режим работы: пн-вс с 9.00 до 17.00 (без выходных)",
    map: "https://yandex.ru/maps/?rtext=56.293286, 43.881311&rtt=mt",
    phone: "+7 (987) 114-50-64",
    phoneNum: +79871145064,
  },
  {
    id: 2,
    title: "Магазин Ордер",
    address:
      "Адрес: г.Нижний Новгород, ул.Героя Попова, 35б, 1 этаж, слева от главного входа, ориентир «Спорткомплекс",
    work: "Режим работы: пн-вс — с 9.00 до 18.00, сб с 9.00 до 17.00, вс и праздничные дни — с 9.00 до 16.00",
    map: "https://yandex.ru/maps/?rtext=56.259671, 43.934992&rtt=mt",
    phone: "+7 (915) 956-65-42",
    phoneNum: +79159566542,
  },
  {
    id: 2,
    title: "АвтоМОЛЛ",
    address:
      "Адрес: г.Нижний Новгород, ул.Суздальская 70, центральный вход, секция 24В, магазин «Красыч»",
    work: "Режим работы: пн-вс с 9.00 до 21.00 (без выходных)",
    map: "https://yandex.ru/maps/?rtext=56.252856, 43.939298&rtt=mt",
    phone: "+7 (920) 016-47-19",
    phoneNum: +79200164719,
  },
];
