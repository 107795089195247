import { Slides, slides } from "../Slider/slides";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
/* import 'swiper/modules/effect-cube.css'; */
/* import 'swiper/css/pagination'; */

import './Slider.scss';

// import required modules
import { EffectCube, Navigation, Pagination, Scrollbar } from 'swiper/modules';

export const Slider = () => {

    return (
        <Swiper
          effect={'cube'}
pagination={{clickable:true}}
/* scrollbar={{draggable:true}} */
navigation
          grabCursor={true}
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          modules={[EffectCube, Pagination, Navigation, Scrollbar]}
          className="mySwiper"
        >
        {slides.map(({id, slide}:Slides) => (
            <SwiperSlide key={id}  >
                <img src={slide} alt={slide}/>
            </SwiperSlide>
        ))}

      </Swiper>
    )
}