import loupe from "../../assets/img/sprite.svg";

export interface NavigationItem {
  id: number;
  item: string;
  link: string;
  svg: string;
}

type NavigationArr = NavigationItem[];

export const navigationData: NavigationArr = [
/*   {
    id: 1,
    item: "Главная",
    link: "/",
  }, */

  {
    id: 2,
    item: "О нас",
    link: "/#about",
    svg: ''
  },

  {
    id: 3,
    item: "Подбор краски",
    link: "/#paint",
    svg: ''
  },

  {
    id: 4,
    item: "Каталог",
    link: "/#catalog",
    svg: ''
  },

  {
    id: 5,
    item: "Новости",
    link: "news",
    svg: ''
  },

  {
    id: 6,
    item: "Отзывы",
    link: "/#reviews",
    svg: ''
  },

  {
    id: 7,
    item: "Контакты",
    link: "/#contacts",
    svg: ''
  },

  {
    id: 8,
    item: '',
    link: "/#catalog",
    svg: loupe,
  }
];
