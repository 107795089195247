import "./NewsPage.scss";
/* import { newsPageData } from "./newsPageData"; */
import { NewsPageItem } from "../../NewsPageItem/NewsPageItem";
import { NewsPageDataArr } from "./newsPageData";
import { FC, useState } from "react";
import svg from "../../../assets/img/sprite.svg";

interface NewsPageProps {
  newsPageData: NewsPageDataArr;
}

export const NewsPage: FC<NewsPageProps> = ({ newsPageData }) => {
  const [sortDate, setSortDate] = useState(newsPageData);

  const handleSort = () => {
    const newsSortArr = [...sortDate];
    let dir = true;
    return newsSortArr.sort((a, b) => {
      if (!dir === false ? a.title < b.title : a.title > b.title) dir = false;
      setSortDate(newsSortArr);
      return -1;
    });
  };

  /*   const handleSort2 = () => {
    const newsSortArr = [...sortDate].sort((a, b) =>
      a.title > b.title ? -1 : 1
    );

    setSortDate(newsSortArr);
  }; */

  return (
    <section className="news p70">
      <div className="container container__news">
        <div className="news__block">
          <h2 className="news__title title">Новости</h2>
          <span className="news__sort">
            сортировать по дате:&nbsp;<button onClick={handleSort}>
            <svg className="news__icon" width="30" height="30" fill="none">
        <use xlinkHref={`${svg}#sort`}></use>
      </svg>
            </button>
          </span>
        </div>
        <ul className="news__list">
          {sortDate.map(({ id, title, date, img, text }) => (
            <li key={id} className="news__item">
              <NewsPageItem
                id={id}
                title={title}
                date={date}
                img={img}
                text={text}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
