import "./Logo.scss";
import logo from "../../assets/img/logo.png";
import { FC } from "react";
import { Link } from "react-router-dom";

interface LogoProp {
  opacity: boolean;
}

export const Logo: FC<LogoProp> = ({ opacity }) => {
  return (
    <div className="logo">
      <Link title="Главная страница" className="logo__link" to={'/'}>
        <img
          className="logo__img "
          style={{ opacity: opacity ? ".6" : "1" }}
          src={logo}
          alt="Logo"
        />
        </Link>
    </div>
  );
};
