import './Header.scss'
import { Logo } from "../../ui/Logo/Logo";
import { Navigation } from "../Navigation/Navigation";
import { BurgerMenu } from '../../ui/BurgerMenu/BurgerMenu';
import { useState } from 'react';

export const Header = () => {

  const [open, setOpen] = useState(false)

    return (
        <header id='header' className= {!open? "header" : 'header open'}>
          <div className="container header__container">
              <Logo opacity={false}/>
            <Navigation open={open} setOpen={setOpen} />
            <BurgerMenu open={open} setOpen={setOpen}/>
        </div>
      </header>
    )
}