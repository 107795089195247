import { BurgerMenuProps } from "../../ui/BurgerMenu/BurgerMenu";
import "./Navigation.scss";
import { navigationData, NavigationItem } from "./navigationData";
import { FC } from "react";
import { HashLink as Link } from "react-router-hash-link";

export const Navigation: FC<BurgerMenuProps> = ({ open, setOpen }) => {
  return (
    <nav className="nav">
      <ul className="nav__list">
        {navigationData.map(({ id, item, link, svg }: NavigationItem) => (
          <li onClick={() => setOpen(!open)} key={id} className="nav__item">
            <Link className="nav__link" to={link}>
              {item}
              {svg && (
                <svg className="nav__search" width="20" height="20">
                  <use xlinkHref={`${svg}#search`}></use>
                </svg>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
