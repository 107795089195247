import { useState } from "react";
import { Logo } from "../../ui/Logo/Logo";
import { Navigation } from "../Navigation/Navigation";

import "./Footer.scss";

export const Footer = () => {

  const [open, setOpen] = useState(false)

  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="container footer__container">
          <Logo opacity={true}/>
          <Navigation open={open} setOpen={setOpen} />
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <span className="footer__span">
            avtokraska.net
            <span className="footer__reg"> &#9415; </span>
            {`2012 - ${new Date().getFullYear()}`}
          </span>
        </div>
      </div>
    </footer>
  );
};
