import { FC } from "react";
import { CatalogItem } from "../CatalogItem/CatalogItem";
import "./CatalogLIst.scss";
import { CatalogData } from "../Catalog/catalogData";

export const CatalogList: FC<CatalogData> = ({
  title,
  dscr,
  img,
  catalogItem,
}) => {
  return (
    <>
      <h3 className="catalog__title-min title-min">{title}</h3>
      <div className="catalog__content-dscr">
        <img src={img} style={{ maxWidth: "150px" }} alt={title} />
        <p className="catalog__content-text text">{dscr}</p>
      </div>
      <ul className="catalog__list">
        {catalogItem.map(({ idItem, titleItem, dscrItem, imgItem }) => (
          <li className="catalog__item" key={idItem}>
            <CatalogItem
              idItem={idItem}
              titleItem={titleItem}
              dscrItem={dscrItem}
              imgItem={imgItem}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
