import flowers from "../../../assets/img/flowers.jpg";
import oldSite from "../../../assets/img/old-site.webp";

export interface NewsPageData {
  id: number;
  title: string;
  date: string;
  img: string;
  text: string;
}

export type NewsPageDataArr = NewsPageData[];

export const newsPageData: NewsPageDataArr = [
  {
    id: 1,
    title: "C Днем Рождения",
    date: "18.11.2024",
    img: flowers,
    text: "Коллектив сети магазинов Красыч Поздравляет нашего руководителя с днем рождения и желет Вам всегда быть такой цветущей, жизнерадостной и успешной женщиной! Пускай Ваша жизнь будет насыщенной, яркой и интересной! Достигайте всех поставленных целей, шагайте только вперед и будьте счастливы!",
  },
  {
    id: 2,
    title: "У нас новый дизайн!",
    date: "17.11.2024",
    img: oldSite,
    text: "Добро пожаловать на обновленный сайт avtokraska.net! На изображение Вы видите версию нашего старого сайта, но коллектив  avtokraska.net много работал и создал для Вас болею удобную версию сайта. Данный сайт является каталогом продукции наших магазинов и носит ознакомительный характер, подробнее узнать о наличии товаров, вы можете по телефонам указанных на сайте.",
  },
];
