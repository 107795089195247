import { Slider } from "../Slider/Slider";
import "./About.scss";

export const About = () => {
  return (
    <section id="about" className="about p70">
      <div className="container about__container">
        <h2 className="about__title title">O нас</h2>
        <div className="about__content">
          <div className="about__left">
            <div className="about__card">
              <p className="about__text text">
                Красыч это сеть магазинов автомобильных принадлежностей
                в&nbsp;Нижнем Новгороде. У&nbsp;нас&nbsp;Вы найдёте всё
                необходимое для проведения лакокрасочных работ (автоэмали, лаки,
                грунты, шпатлевки, герметики, обезжириватели, очистители,
                абразивные и&nbsp;полировочные материалы), а&nbsp;также литые
                диски, автохимию и&nbsp;многое другое.
              </p>
              <p className="about__text text text2">
                В&nbsp;наших магазинах представлены десятки брендов и&nbsp;более
                1000&nbsp;наименований. Ассортимент компании постоянно
                пополняется. Мы&nbsp;непрестанно работаем над его расширением.
                Коллектив магазина Красыч старается сделать всё для того, чтобы
                клиент был доволен! Мы&nbsp;настроены на&nbsp;долгосрочное
                сотрудничество, поэтому относимся к&nbsp;каждому клиенту
                индивидуально, предлагая максимально выгодные условия!
                Мы&nbsp;хотим чтобы покупатели, сделав покупку, возвращались
                к&nbsp;нам снова и&nbsp;снова!
              </p>
            </div>
          </div>
          <div className="about__right">
            <Slider />
          </div>
        </div>
        <ul className="about__bottom">
          <li className="about__bottom-li">
            <div className="about__item about__item_result">
              <p className="about__text about__text_item">
                Большой выбор товара в наличии и под заказ
              </p>
            </div>
          </li>
          <li className="about__bottom-li">
            <div className="about__item about__item_personally">
              <p className="about__text about__text_item">
                Мы всегда рады Вас прокунсультировать 
              </p>
            </div>
          </li>
          <li className="about__bottom-li">
            <div className="about__item about__item_problems">
              <p className="about__text about__text_item">
                Для постоянных клиентов скидки
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
