import { ModalBurger } from "../ModalBurger/ModalBurger";
import "./Contacts.scss";
import svg from "../../assets/img/sprite.svg";

import { useState } from "react";
import { ContactsType, contactsData } from "./contactsData";
export const Contacts = () => {
  const [open, setOpen] = useState(false);
  const [marker, setMarker] = useState<ContactsType>({
    id: 0,
    title: "",
    address: "",
    map: "",
    work: "",
    phone: "",
    phoneNum: 0,
  });
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLButtonElement;
    const id = target.id;
    setOpen(!open);
    if (id === "1") {
      setMarker(contactsData[0]);
    }
    if (id === "2") {
      setMarker(contactsData[1]);
    }

    if (id === "3") {
      setMarker(contactsData[2]);
    }
  };

  return (
    <section
      id="contacts"
      className={!open ? "contacts p70" : "contacts p70 open"}
    >
      <div className="container contacts__container">
        <h2 className="contacts__title title">Контакты</h2>
        <div className="contacts__dscr">
          <div className="contacts__maps">
            <ModalBurger open={open} setOpen={setOpen}>
              <>
                <h2 className="modal-burger__title title">{marker.title}</h2>
                <address className="modal-burger__address">
                  <p className="modal-burger__text text">{marker.address}</p>
                  <a className="modal-burger__link" href={marker.map}>Построить маршрут</a>
                  <p className="modal-burger__text text">{marker.work}</p>
                  <a className="modal-burger__link modal-burger__link_tel" href={`tel:${marker.phoneNum}`}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use xlinkHref={`${svg}#tel`}></use>
                    </svg>
                    {marker.phone}
                  </a>
                </address>
              </>
            </ModalBurger>
            <button
              onClick={handleClick}
              id="1"
              className="contacts__marker contacts__marker_1"
            ></button>
            <button
              onClick={handleClick}
              id="2"
              className="contacts__marker contacts__marker_2"
            ></button>
            <button
              onClick={handleClick}
              id="3"
              className="contacts__marker contacts__marker_3"
            ></button>
          </div>
          {/* <div className="form">
            <h3 className="form__title">Кузбасский авторынок</h3>
            <p className="form__text">
              Режим работы: пн-вс с 9.00 до 17.00 (без выходных) Адрес: г.Нижний
              Новгород, ул.Удмуртская 3к1, павильон «Автозапчасти», секция 22 и
              35. Телефон: +7 (987) 114-50-64
            </p>
            <h3 className="form__title">Магазин Ордер</h3>
            <p className="form__text">
              Режим работы: пн-вс — с 9.00 до 18.00, сб с 9.00 до 17.00, вс и
              праздничные дни — с 9.00 до 16.00 Адрес: г.Нижний Новгород,
              ул.Героя Попова, 35б, 1 этаж, слева от главного входа, ориентир
              «Спорткомплекс». Телефон: +7 (915) 956-65-42
            </p>
            <h3 className="form__title">ТЦ «АвтоМОЛЛ»</h3>
            <p className="form__text">
              Режим работы: пн-вс с 9.00 до 21.00 (без выходных) Адрес: г.Нижний
              Новгород, ул.Суздальская 70, центральный вход, секция 24В, магазин
              «Красыч». Телефон: +7 (920) 016-47-19
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
};
