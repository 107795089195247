import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainPage } from "./components/Pages/MainPage/MainPage";
import { NotFound } from "./components/Pages/NotFound";
import { TopScrollButton } from "./ui/TopScrollButton/TopScrollButton";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { NewsPage } from "./components/Pages/NewsPage/NewsPage";
import { newsPageData } from "./components/Pages/NewsPage/newsPageData";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <main className="main">
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<MainPage />} />
          <Route
            path="/news"
            element={<NewsPage newsPageData={newsPageData} />}
          />
        </Routes>
      </main>
      <Footer />
      <TopScrollButton />
    </BrowserRouter>
  );
}

export default App;
