import { FC, useState } from "react";
import { Modal } from "../Modal/Modal";
import "./CatalogItem.scss";
import { CatalogDataItem } from "../Catalog/catalogData";

export const CatalogItem: FC<CatalogDataItem> = ({
  titleItem,
  dscrItem,
  imgItem,
}) => {
  const [modalActive, setModalActive] = useState(false);

  return (
    <article
      className="catalog__card"
      style={{ backgroundImage: `url(${imgItem})` }}
    >
      
      <div className="catalog__descr">
      {/* <img style={{maxWidth:'150px', marginBottom:'10px'}} src={imgItem} alt={titleItem} className="card__img" /> */}
        <h3 className="catalog__name title-min">{titleItem}</h3>
        <p className="catalog__text text">{dscrItem}</p>
        <button
          onClick={() => setModalActive(true)}
          className="catalog__btn btn"
        >
          Подробнее
        </button>
      </div>
      <Modal active={modalActive} setActive={setModalActive}>
        <>
          <h3 className="title-min">{titleItem}</h3>
          <p className="text">{dscrItem}</p>
        </>
      </Modal>
    </article>
  );
};
