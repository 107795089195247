import { FC } from "react";
import "./BurgerMenu.scss";

export interface BurgerMenuProps {
  open: boolean,
  setOpen:(value:boolean)=>void,
}
export const BurgerMenu:FC<BurgerMenuProps> = ({open, setOpen}) => {

    return (
        <button onClick={()=>setOpen(!open)} id="burger-btn" className="burger-btn">
        <span></span><span></span><span></span>
      </button>
    )
};
