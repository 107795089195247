import { About } from "../../About/About";
import { Catalog } from "../../Catalog/Catalog";
import { CatalogContext } from "../../Catalog/CatalogContext";
import { catalogData } from "../../Catalog/catalogData";
import { Contacts } from "../../Contacts/Contacts";
import { Hero } from "../../Hero/Hero";
import { Paint } from "../../Paint/Paint";
import { Reviews } from "../../Reviews/Reviews";
import "./MainPage.scss";

export const MainPage = () => {

  return (
    <>
      <Hero />
      <About />
      <CatalogContext.Provider value={catalogData}>
      <Catalog catalogData = {catalogData}/>
      </CatalogContext.Provider>
      <Paint />
      <Reviews />
      <Contacts />
    </>
  );
};
