import "./Hero.scss";

export const Hero = () => {
  return (
    <section className="hero">
      <h1 className="visually-hidden">
        Красыч - сеть магазинов, автоэмалей, литых дисков, шпатлевок ,лаков,
        грунтов для автомобилей, ИП Калинина
      </h1>
      <div className="container hero__container">
        <div className="hero__dscr">
          <h1 className="hero__title">Магазин Красыч</h1>
          <p className="hero__text text">
            В&nbsp;наших магазинах вы&nbsp;найдёте, широкий выбор автоэмали
            и&nbsp;всё необходимое для проведения лакокрасочных работ (лаки,
            грунты, шпатлевки, герметики, обезжириватели, очистители, абразивные
            и полировочные материалы)
          </p>
          <a className="btn hero__btn" href="/#catalog">
            Перейти в каталог
          </a>
        </div>
      </div>
    </section>
  );
};
