import { FC, ReactElement } from "react";
import svg from "../../assets/img/sprite.svg";
import "./Modal.scss";

interface ModalProps {
  active: boolean;
  setActive: (value: boolean) => void;
  children: ReactElement;
}

export const Modal: FC<ModalProps> = ({ active, setActive, children }) => {
  return (
    <div
      className={active ? "modal active" : "modal"}
      onClick={() => setActive(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={active ? "modal__content active" : "modal__content"}
      >
        <button onClick={() => setActive(false)}>
          <svg className="modal__close" width="30" height="30">
            <use xlinkHref={`${svg}#x`}></use>
          </svg>
        </button>
        {children}
      </div>
    </div>
  );
};
